<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add User') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-overlay
            :show="$store.state.app.loadingCreate"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />

          <!-- Last name -->
          <validation-provider
            #default="validationContext"
            name="Фамилия"
            rules="required"
          >
            <b-form-group
              label="Фамилия"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Firt Name -->
          <validation-provider
            #default="validationContext"
            name="Имя"
            rules="required"
          >
            <b-form-group
              label="Имя"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="userData.first_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Отчество"
          >
            <b-form-group
              label="Отчество"
              label-for="surname"
            >
              <b-form-input
                id="surname"
                v-model="userData.surname"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Телефон"
            rules="required"
          >
            <b-form-group
              label="Телефон"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                v-mask="'+7(###) ###-####'"
                placeholder="+7(987) 654-3210"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Роль"
            rules="required"
          >
            <b-form-group
              label="Роль"
              label-for="role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="userData.role === 'client'">
            <validation-provider
              #default="validationContext"
              name="Тариф"
              rules="required"
            >
              <b-form-group
                label="Тариф"
                label-for="role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.tariff"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tariffsOptions"
                  :get-option-label="option => option.name"
                  :clearable="false"
                  input-id="tariff"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    tariffsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      surname: '',
      email: '',
      phone: '',
      kpi_target: '',
      kpi_sum: '',
      tariff: null,
      role: 'client',
    }

    const sendData = {
      fio: '',
      email: '',
      phone: '',
      login: '',
      kpi_target: '',
      kpi_sum: '',
      tariff: null,
      role: 'client',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const tariffOptions = [
      { label: 'Basic', value: 'Basic' },
      { label: 'Standard', value: 'Standard' },
      { label: 'Enterprise', value: 'Enterprise' },
    ]

    const onSubmit = () => {
      sendData.fio = `${userData.value.last_name} ${userData.value.first_name} ${userData.value.surname}`.trim()
      sendData.phone = userData.value.phone
      sendData.email = userData.value.email
      sendData.login = userData.value.email
      sendData.kpi_target = userData.value.kpi_target
      sendData.kpi_sum = userData.value.kpi_sum
      sendData.tariff = userData.value.tariff
      sendData.role = userData.value.role

      store.dispatch('app-users/addUser', sendData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      tariffOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<template>

  <div>

    <add-new-user
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :tariffs-options="tariffsOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Add User') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <div class="relative">
        <b-overlay
          :show="loading"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Нет данных"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(createdAt)="data">
            <span class="text-nowrap">
              {{ data.item.createdAt | moment("DD MMMM GGGG") }}
            </span>
          </template>

          <template #cell(tariff)="data">
            <span class="text-nowrap">
              {{ data.item.tariff && data.item.tariff.name }}
            </span>
          </template>

          <template #cell(phone)="data">
            <span class="text-nowrap">
              {{ data.item.phone }}
            </span>
          </template>

          <template #cell(paid_before)="data">
            <div class="text-nowrap font-weight-bold" :class="`text-${paidDateColor(data.item.paid_before)}`">
              {{ data.item.paid_before | moment("DD MMMM GGGG") }}
            </div>
            <div
              v-if="convertMiliseconds(new Date(data.item.paid_before) - new Date(), 'd') >= 0"
              class="text-nowrap font-weight-bold" :class="`text-${paidDateColor(data.item.paid_before)}`"
            >
              До оплаты {{ convertMiliseconds(new Date(data.item.paid_before) - new Date(), 'd') }} {{ declOfNum(convertMiliseconds(new Date(data.item.paid_before) - new Date(), 'd'), ['день', 'дня', 'дней']) }}
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(fio)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar && `${server}/${data.item.avatar}`"
                  :text="avatarText(data.item.fio)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'user-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'user-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fio }}
              </b-link>
              <small class="text-muted">@{{ data.item.login }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{ resolveUserRoleText(data.item.role) }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ resolveUserStatusText(data.item.status) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'user-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('Details') }}</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'user-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status !== 0"
                @click="blockUser(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Block') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status !== 10 && data.item.status !== 9"
                @click="unBlockUser(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Unblock') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 9"
                @click="confirmUser(data.item)"
              >
                <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">{{ $t('Confirm') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 10"
                @click="unconfirmUser(data.item)"
              >
                <feather-icon icon="XSquareIcon" />
                <span class="align-middle ml-50">{{ $t('Remove confirmation') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteUser(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { convertMiliseconds, declOfNum } from '@core/utils/utils'
import store from '@/store'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import usersStoreModule from '../usersStoreModule'
import AddNewUser from './AddNewUser.vue'

export default {
  components: {
    UsersListFilters,
    AddNewUser,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BOverlay,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, usersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const server = process.env.VUE_APP_SERVER

    const {
      loading,
      fetchUsers,
      fetchTariffs,
      unBlockUser,
      blockUser,
      deleteUser,
      confirmUser,
      unconfirmUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      paidDateColor,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRoleText,
      resolveUserStatusVariant,
      resolveUserStatusText,

      roleOptions,
      tariffsOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useUsersList()

    fetchTariffs()

    return {
      server,
      loading,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      unBlockUser,
      blockUser,
      deleteUser,
      confirmUser,
      unconfirmUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRoleText,
      resolveUserStatusVariant,
      resolveUserStatusText,
      avatarText,
      paidDateColor,
      convertMiliseconds,
      declOfNum,

      roleOptions,
      tariffsOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import { ref, watch, computed } from '@vue/composition-api'
import { convertMiliseconds } from '@core/utils/utils'
import { debounce } from 'lodash'
import store from '@/store'

export default function useUsersList() {
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = ref([
    /* eslint-disable */
    { key: 'createdAt', sortable: true, label: 'Дата регистрации', thClass: 'text-left' },
    { key: 'paid_before', sortable: true, label: 'Оплачено до' },
    { key: 'tariff', sortable: true, label: 'Тариф' },
    { key: 'fio', sortable: true, label: 'ФИО', thClass: 'text-left' },
    { key: 'email', sortable: true, label: 'Email', thClass: 'text-left' },
    { key: 'phone', sortable: true, label: 'Телефон', thClass: 'text-left' },
    { key: 'role', sortable: true, label: 'Роль', thClass: 'text-left' },
    {
      key: 'status', sortable: true, label: 'Статус', thClass: 'text-left', tdClass: 'text-left',
    },
    { key: 'actions', label: '', sortable: false },
    /* eslint-enable */
  ])
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(undefined)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref('client')
  const statusFilter = ref('10')

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    if (refUserListTable && refUserListTable.value) {
      refUserListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  watch([roleFilter], () => {
    if (roleFilter.value === 'admin') {
      tableColumns.value = [
        { key: 'createdAt', sortable: true, label: 'Дата регистрации' },
        { key: 'fio', sortable: true, label: 'ФИО' },
        { key: 'email', sortable: true, label: 'Email' },
        { key: 'phone', sortable: true, label: 'Телефон' },
        { key: 'role', sortable: true, label: 'Роль' },
        { key: 'status', sortable: true, label: 'Статус' },
        { key: 'actions', label: '', sortable: false },
      ]
    }

    if (roleFilter.value === 'client') {
      tableColumns.value = [
        { key: 'createdAt', sortable: true, label: 'Дата регистрации' },
        { key: 'paid_before', sortable: true, label: 'Оплачено до' },
        { key: 'tariff', sortable: true, label: 'Тариф' },
        { key: 'fio', sortable: true, label: 'ФИО' },
        { key: 'email', sortable: true, label: 'Email' },
        { key: 'phone', sortable: true, label: 'Телефон' },
        { key: 'role', sortable: true, label: 'Роль' },
        { key: 'status', sortable: true, label: 'Статус' },
        { key: 'actions', label: '', sortable: false },
      ]
    }
    refetchData()
  })

  const blockUser = user => {
    store.dispatch('app-users/blockUser', user).then(() => {
      refetchData()
    })
  }

  const unBlockUser = user => {
    store.dispatch('app-users/unBlockUser', user).then(() => {
      refetchData()
    })
  }

  const deleteUser = user => {
    store.dispatch('app-users/deleteUser', user).then(() => {
      refetchData()
    })
  }

  const unconfirmUser = user => {
    store.dispatch('app-users/unconfirmUser', user).then(() => {
      refetchData()
    })
  }

  const confirmUser = user => {
    store.dispatch('app-users/confirmUser', user).then(() => {
      refetchData()
    })
  }

  const loading = ref(true)
  const asyncFetchUsers = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-users/fetchUsers', {
        params: {
          filter: JSON.stringify({
            status: statusFilter.value || undefined,
            role: roleFilter.value || undefined,
            fio: searchQuery.value ? {
              $regex: searchQuery.value,
              $options: 'i',
            } : undefined,
          }),
          perPage: perPage.value,
          page: currentPage.value,
        },
      })
      .then(response => {
        const { data } = response.data

        callback(data)
        totalUsers.value = response.count
        loading.value = false
      })
      .catch(() => {
        loading.value = false
      })
  }

  const fetchUsers = debounce((ctx, callback) => asyncFetchUsers(ctx, callback), 500)

  const tariffsOptions = ref([])

  const fetchTariffs = () => {
    store
      .dispatch('app-users/fetchTariffs')
      .then(response => {
        const { data } = response
        tariffsOptions.value = data.tariffs
      })
      .catch(() => {
        tariffsOptions.value = []
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'client') return 'primary' // Клиент
    if (role === 'admin') return 'danger' // Админ
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'client') return 'UserIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserRoleText = role => {
    if (role === 'client') return 'Клиент'
    if (role === 'admin') return 'Админ'
    return 'Клиент'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 10) return 'success'
    if (status === 9) return 'secondary'
    return 'primary'
  }

  const resolveUserStatusText = status => {
    if (status === 0) return 'Заблокирован'
    if (status === 10) return 'Активен'
    if (status === 9) return 'Не подтвержден'
    return 'primary'
  }

  const fullName = item => {
    const fname = `${item.first_name} ${item.last_name}`
    return fname
  }

  const paidDateColor = date1 => {
    // const days = convertMiliseconds(new Date(data.item.paid_before) - new Date(), 'd')
    const days = convertMiliseconds(new Date(date1) - new Date(), 'd')

    if (days >= 14) return 'success'
    if (days >= 7 && days < 14) return 'warning'
    if (days >= 0 && days < 7) return 'danger'
    if (days <= 0) return 'danger'

    return 'success'
  }

  const roleOptions = [
    { label: 'Админ', value: 'admin' },
    { label: 'Клиент', value: 'client' },
  ]

  const statusOptions = [
    { label: 'Заблокирован', value: '0' },
    { label: 'Активен', value: '10' },
    { label: 'Не подтвержден', value: '9' },
  ]

  return {
    loading,
    fetchUsers,
    fetchTariffs,
    unBlockUser,
    blockUser,
    deleteUser,
    confirmUser,
    unconfirmUser,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    fullName,
    paidDateColor,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserRoleText,
    resolveUserStatusVariant,
    resolveUserStatusText,
    refetchData,

    roleOptions,
    tariffsOptions,
    statusOptions,

    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
